<template>
  <div id="navtitle">
    <!-- header -->
    <Header/>
    <div class="container">
      <div class="project">
        <div>
          <div class="item-box" @click="$router.push('/report/task')">
            <div class="item-info">
              <i class="fa fa-area-chart"></i>
              <h2>项目报表</h2>
            </div>
          </div>
          <div class="item-box" @click="$router.push('/id-reviews')">
            <div class="item-info">
              <i class="fa fa-id-card-o"></i>
              <h2>实名认证审核</h2>
            </div>
          </div>
          <div class="item-box" @click="$router.push('/points')">
            <div class="item-info">
              <i class="fa fa-star-o"></i>
              <h2>泛函币管理</h2>
            </div>
          </div>
          <div class="item-box" @click="$router.push('/limit')">
            <div class="item-info">
              <i class="fa fa-bookmark-o"></i>
              <h2>权限管理</h2>
            </div>
          </div>
          <div class="item-box" @click="$router.push('/tips-admin/system')">
            <div class="item-info">
              <i class="fa fa-bullhorn"></i>
              <h2>公告管理</h2>
            </div>
          </div>
          <div class="item-box" @click="$router.push('/team-info')">
            <div class="item-info">
              <i class="fa fa-list-alt"></i>
              <h2>工会信息管理</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
export default {
  name: 'home',
  components: {
    Header,
    Footer
  }
}
</script>

<style>
/* footer 固定在页面底部 */

.project {
  border-radius: 5px;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 2px hsla(165, 9%, 74%, 0.95);
  box-shadow: 0 1px 2px hsla(165, 9%, 74%, 0.95);
  margin-bottom: 20px;
  padding: 45px 50px 50px;
  position: relative;
  margin-top: 20px;
  display: inline-block;
  width: 100%;
  font-size: 0;
}

.item-box {
  margin-top: 20px;
  margin-right: 0.5%;
  margin-left: 0.5%;
  margin-bottom: 15px;
  position: relative;
  min-height: 225px;
  /* min-width: 250px; */
  width: 32%;
  display: inline-block;
  background-color: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 4px 0 rgba(204, 204, 204, 0.35);
  box-shadow: 0 1px 4px 0 rgba(204, 204, 204, 0.35);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.item-box:hover {
  background: #f0f0f0;
}

.item-info {
  position: absolute;
  text-align: center;
  padding: 55px 20px 10px;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.item-info i {
  font-size: 70px;
  color: #409eff;
}

.item-info h2 {
  font-size: 20px;
  color: #333;
  font-weight: normal;
  padding-top: 18px;
  padding-bottom: 15px;
}

@media (max-width: 768px) {
  .project {
  text-align: center;
  }
  .item-box {
  min-width: 250px;
  }
}

.container {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}
</style>
